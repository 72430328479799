import {ErrorElement, SearchResult, propify} from "./searchresult.coffee"
import React from 'react'
import ReactDOM from 'react-dom'
import format from "date-fns/format"

e = React.createElement

gprops =
  error_message: undefined
  total: 0
  results: []
  loading: false
  overall_total: 0

seconds = 10
methods =
  api: true
  manual: true
  automatic: true

render = () ->
  ReactDOM.render(rootElement(gprops), document.getElementById('scans'))

toggleMethod = (x) ->
  methods[x] = not methods[x]
  search_for()

rootElement = (props) ->
  e('div', {},
    e(ErrorElement, {error_message: props.error_message}),
    e('h3', {},
      ' Recent scans ',
      e("img", {src: "/img/loading.svg", className: "height09em"}), e('small', {className: "hidden-sm hidden-xs"}, "Updates every #{seconds}s")
      props.loading and e('small', {className: "hidden-sm hidden-xs"}, " - Updating...")
      not props.loading and e('small', {className: "hidden-sm hidden-xs"}, " - Last update: #{format(new Date(), "HH:mm:ss")}")
    )
    e('table', { className: 'table table-striped' },
      e('thead', {},
        e('tr', {},
          e('th', { className: 'right hidden-xs' },
            e('span', { className: 'light glyphicon glyphicon-lock hidden-xs' }, '')
          ),
          e('th', {}, 'URL'),
          e('th', {className: "hidden-xs"}, 'Age'),
          e('th', { className: 'right hidden-xs' }, 'Size'),
          e('th', { className: 'right hidden-xs' },
            e('span', { className: 'glyphicon glyphicon-transfer', title: "HTTP transactions" }, '')
          ),
          e('th', { className: 'right hidden-xs' }, 'IPs'),
          e('th', { className: 'right hidden-xs', title: "Countries"  },
            e('span', { className: 'glyphicon glyphicon-flag' }, '')),
          e('th', {},
            e('span', { className: 'glyphicon glyphicon-home', title: "Page country" }, '')
          )
        )
      ),
      e('tbody', {}, props.results.map((x) -> e(SearchResult, x))),
    )
  )

search_for = () ->
  gprops.loading = true
  render()
  fetch("/json/live/").then((data) ->
    return data.json()
  ).then((data) ->
    gprops.total = data.total
    gprops.results = data.results.slice(0,10).map((d) ->
      propify(d, {show_new: true, hide_visibility: true}) 
    )
    return
  ).catch((err) ->
    gprops.error_message = err.responseJSON.message
    return
  ).finally((err) ->
    gprops.loading = false
    render()
    return
  )
  return

setup_visibility = (e) ->
  if window.localStorage
    target = window.localStorage.getItem("visibility")
    if target and target in ["public", "unlisted", "private"]
      document.querySelector("input.visibility[value=#{target}]").click()

    target = window.localStorage.getItem("country")
    if target
      document.querySelector("input.country[value=#{target}]").click()
    else
      $("#countrybtn").hide()

visibility_selected = (e) ->
  target = document.querySelector('input.visibility:checked').value
  if e and window.localStorage
    window.localStorage.setItem('visibility', target)
  if target is "public"
    $("#submitbtn").addClass("btn-success").removeClass("btn-warning").removeClass("btn-info")
    $("#submitbtn_text").text("Public Scan")
  else if target is "unlisted"
    $("#submitbtn").addClass("btn-info").removeClass("btn-warning").removeClass("btn-success")
    $("#submitbtn_text").text("Unlisted Scan")
  else
    $("#submitbtn").removeClass("btn-success").addClass("btn-warning").removeClass("btn-info")
    $("#submitbtn_text").text("Private Scan")

country_selected = (e) ->
  target = document.querySelector('input.country:checked').value
  if e and window.localStorage
    window.localStorage.setItem('country', target)
  if target
    $("#countrybtn").html("<span class=\"flag-icon flag-icon-#{target}\"></span> #{target.toUpperCase()}")
    $("#countrybtn").show()
  else
    $("#countrybtn").hide()

setup_click_handler = () ->
  $("input.visibility").on("change", visibility_selected)
  $("input.country").on("change", country_selected)

fill_url = () ->
  if window.location.hash
    $("input#url").val(window.location.hash.replace(/^#/, ""))
    history.pushState("", document.title, window.location.pathname + window.location.search)

main = ->
  setup_click_handler()
  setup_visibility()
  gprops.results = Array(10).fill(undefined).map((x) -> {key: Math.random()})
  render()
  fill_url()
  search_for()
  setInterval(() ->
    search_for()
  , seconds * 1000)

main()
