import React from 'react'
import formatDistanceStrict from "date-fns/formatDistanceStrict"
import parseJSON from "date-fns/parseJSON"
import startCase from "lodash/startCase"
import uniqBy from "lodash/uniqBy"
import punycode from "punycode"
e = React.createElement

tryPuny = (string) ->
  try
    return punycode.toUnicode(string)
  catch err
    return string

truncate = (str, num) ->
  if (str.length <= num)
    return str
  return str.slice(0, num) + '...'

readableFileSize = (size) ->
  unless size
    return ""
  units = [
    "B"
    "KB"
    "MB"
    "GB"
    "TB"
    "PB"
    "EB"
    "ZB"
    "YB"
  ]
  i = 0
  while size >= 1024
    size /= 1024
    ++i
  size.toFixed(0) + " " + units[i]

visibility_icon = (method) ->
  switch method
    when "public" then "label-success"
    when "private" then "label-warning"
    when "unlisted" then "label-info"
    else "label-default"

propify = (d, aprops) ->
  defaults =
    offset: 0
    new: false
    detail: 0

  for key, value of defaults
    unless key of aprops
      aprops[key] = value

  props = aprops

  try
    unicodeTask = d.task.url.replace(/^https?:\/\//, '').split("/").map((x) -> tryPuny(x)).join("/")
    unicodePage = d.page.url.replace(/^https?:\/\//, '').split("/").map((x) -> tryPuny(x)).join("/")
  catch
    unicodeTask = d.task.url
    unicodePage = d.page.url
  return 
    url: unicodeTask
    page_url: unicodePage
    isPunycode: (d.page.domain or "").match(/xn--/)
    protocol: if d.task.url.match(/^https:.*/) then 'secure' else 'insecure'
    files: d.files or []
    time: d.task.time
    size: d.stats.encodedDataLength or 0
    uuid: d['_id']
    key: d["_id"]
    method: d.task.method or 'manual'
    task: d.task or {}
    uniqIPs: d.stats.uniqIPs or 0
    uniqCountries: d.stats.uniqCountries or 0
    homeCountry: d.page.country or ''
    requests: d.stats.requests or 0
    page: d.page or {}
    failed: !d.stats.requests
    detail: props.detail or 0
    show_new: props.show_new or false
    visibility: if props.hide_visibility then false else d.task.visibility
    sort: d.sort
    brand: d.brand

SecureLock = (props) ->
  if props.protocol is "secure"
    e('span', { className: 'glyphicon glyphicon-lock green light' }, '') 
  else
    e('span', { className: 'glyphicon glyphicon-unchecked light' }, '')

RequestCount = (props) ->
  if props.requests > 100 and props.requests <= 200
    e("span", {className: "orange"}, props.requests)
  else if props.requests > 200 and props.requests <= 300
    e("span", {className: "red"}, props.requests)
  else if props.requests > 300
    e("span", {className: "red bold"}, props.requests)
  else
    e("span", {className: "green"}, props.requests)

MethodIcon = (props) ->
  method_icon = ""
  switch props.method
    when 'manual'
      method_icon = 'user'
    when 'api'
      method_icon = 'qrcode'
    when 'automatic'
      method_icon = 'globe'
    else
      method_icon = 'question-sign'
  return e('span', { title: "Submitted via #{props.method}", className: "halflight glyphicon glyphicon-#{method_icon} pull-right hidden-xs hidden-sm" }, '')

SearchResult = (props) ->
  unless Object.keys(props).length > 2
    return e('tr', {key: props.index}, e("td", {className: "right hidden-xs", colSpan: 1}, e("img", {src: "/img/loading.svg", className: "height1em"})), e("td", {colSpan: 7, className: "text-muted"}, "Loading..."))
  else
    e 'tr', {
      key: props.uuid
      className: if props.show_new then "new-result" else ""
    },
      e('td', { className: 'right hidden-xs' },
        props.detail > 0 and e('span', {className: "text-muted"}, props.index, e('span', {}, e('br'))),
        props.page.ip and e(SecureLock, {protocol: props.protocol})
      ),

      e('td', { className: 'break-all url' }, 
        props.visibility and e("span", {className: "pull-right text-right capitalize"},  
          props.brand and e("span", {className: "label label-danger right5"}, "Malicious"),
          props.failed and e('span', {}, e('span', { className: 'label label-danger halfgray right5' }, 'Failed'))
          e('span', {className: "halfgray label hidden-xs " + visibility_icon(props.visibility)}, startCase(props.visibility)),
          props.detail > 0 and e("br", {}),
          (props.detail > 0) and props.page.status and e('span', {className: "label label-simple text-info"}, e("a", {href: "#page.status:#{props.page.status}"}, props.page.status))
        ),
        props.detail > 0 and e('b', {}, 'URL: '),
        e('a', { href: "/result/#{props.uuid}/", title: props.page_url }, tryPuny(props.page_url).slice(0, 79) + (if props.page_url.length > 80 then '...' else '')),
          props.isPunycode and e('span', {}, " ", e('span', { className: 'label label-warning', title: "Internationalized Domain Name" }, 'IDN')),
          props.files.length > 0 and e("div", {}, 
            e("small", {}, 
              e("b", {}, "Downloaded Files:"),
              e("small", {}, props.files.map((x) ->
                text = "#{truncate(x.filename, 50)}"
                if x.filesize
                  text += " (#{readableFileSize(x.filesize)})"
                return e("span", {className: "simpletag left5 text-info bold", title: x.filename, key: x.sha256}, text)
              ))
            )
          ),
          e('small', {}, props.detail > 0 and props.url and props.page_url.replace(/\/$/, "") != props.url.replace(/\/$/, "") and e('div', {}, e('b', {}, 'Redirect from: '),
            e('a', { href: "#page.url:%22#{props.url}%22", title: props.url }, tryPuny(props.url).slice(0, 79) + (if props.url.length > 80 then '...' else ''))),
            props.detail > 0 and e('div', {className: (if props.additional then "halflight" else "")},
              props.page and props.page.ip and e('div', {}, e('b', {}, 'IP: '),
                e('a', { href: '#page.ip:"' + props.page.ip + '"' }, props.page.ip),
                props.page.ptr and e('span', {},
                  e('b', {}, ' - PTR: '),
                  e('a', { href: '#page.ptr:' + props.page.ptr }, props.page.ptr)
                )
                props.page.server and e("span", {},
                  e('b', {}, ' - Server: '),
                  e('a', { href: '#page.server:%22' + props.page.server + '%22' }, props.page.server.slice(0, 39) + (if props.page.server.length > 40 then '...' else ''))
                )
              ),
              props.page.country and e('div', {}, e('b', {}, 'GeoIP: '),
                e('span', className: 'flag-icon flag-icon-' + (props.page.country or '').toLowerCase()),
                  e('span', {}, ' '),
                props.page.city and e('a', { href: '#page.city:' + props.page.city }, props.page.city),
                props.page.city and e('span', {}, ', '),
                e('a', { href: '#page.country:' + props.page.country }, props.page.country),
                  e('span', {}, ' - '),
                e('a', { href: '#page.asn:' + props.page.asn }, props.page.asn),
                  e('span', {}, ' (' + props.page.asnname + ')')
              ),
              props.task.tags and props.task.tags.length > 0 and e('div', {}, e('b', {}, 'Tags: '),
                props.task.tags.map((x) ->
                  e('a', {key: x, href: '#task.tags:"' + x + '"' },
                    e("div", {className: "simpletag right5"}, x)
                  )
                )
              )
            )
          ),
          props.additional and e('div', {},
            e('hr', {className: "tight"}),
            props.additional
          )
      ),
      e('td', { className: 'nowrap hidden-xs' }, 
        formatDistanceStrict(new Date(), parseJSON(props.time)),
        props.detail is 0 and e(MethodIcon, {method: props.task.method}), 
        props.detail > 0 and props.task.method and e('div', {className: "hidden-xs"},
          e('small', {},
          e('b', {}, 'Via: '),
            e('a', { href: '#task.method:' + props.task.method }, props.task.method),
              props.task.source and e('span', {},
              e('br'),
              e('b', {}, 'Src: '),
              e('a', { href: '#task.source:' + props.task.source }, truncate(props.task.source, 10))))
          )
      ),
      e('td', { className: 'right hidden-xs nowrap' }, readableFileSize(props.size)),
      e('td', { className: 'right hidden-xs'}, e(RequestCount, {requests: props.requests })),
      e('td', { className: 'right hidden-xs' }, props.uniqIPs),
      e('td', { className: 'right hidden-xs' }, props.uniqCountries),
      e('td', {}, e('span', className: 'flag-icon flag-icon-' + props.homeCountry.toLowerCase()))

ErrorElement = (props) ->
  if props.error_message
    return e('div', { className: 'panel panel-warning' },
      e('div', { className: 'panel-heading' },
      e('h3', { className: 'panel-title' }, 'Error executing the search')),
      e('div', { className: 'panel-body' },
      e('p', {}, 'There was an error executing your search, please adjust your search-term and try again. Message:'),
      e('tt', {},
      props.error_message)))
  return null

class LoadingElement extends React.Component
  render: ->
    if @props.loading == true
      return e('div', { className: 'progress progress-striped active' }, e('div', {
        className: 'progress-bar progress-bar-success width100'
      }, ''))
    null

class RootElement extends React.Component
  constructor: (props) ->
    super(props)
    if window.localStorage
      detail = parseInt(window.localStorage.getItem('searchdetail')) || 0
    else
      detail = 0
    @state =
      detail: props.detail or detail
      collapse: false

  toggleCollapse: () =>
    @setState({collapse: not @state.collapse})

  toggleDetail: () =>
    detail = (@state.detail + 1) % 2
    if window.localStorage
      window.localStorage.setItem('searchdetail', detail)
    @setState({detail: detail})

  render: ->
    e('div', {},
      e(ErrorElement, {error_message: @props.error_message}),
      #e(LoadingElement, {loading: @props.loading}),
      e('h3', {}, 'Search results ',
        @props.loading or e('small', {}, '(' + @props.results.length + ' / ' + @props.total, @props.has_more and e("span", {}, "+"), e("span", {className: "hidden-sm hidden-xs"}, ', sorted by date, took ' + @props.took + 'ms)')),
        @props.loading and e("small", {}, "Loading ... ", e("img", {src: "/img/loading.svg", className: "height1em"})),
        e("span", {className: "pull-right"}, 
          e("button", {className: "btn btn-info btn-xs right5", onClick: @toggleCollapse},
            @state.collapse and [e("span", {key: "icon", className: "glyphicon glyphicon-resize-small"}), e("span", {key: "text"}, " Collapsed by Hostname")],
            not @state.collapse and [e("span", {key: "icon", className: "glyphicon glyphicon-resize-full"}), e("span", {key: "text"}, " Showing All Hits")]
          ),
          e("button", {className: "btn btn-default btn-xs " , onClick: @toggleDetail},
            @state.detail > 0 and [e("span", {key: "icon", className: "glyphicon glyphicon-eye-open"}), e("span", {key: "text"}, " Details: Visible")],
            @state.detail is 0 and [e("span", {key: "icon", className: "glyphicon glyphicon-eye-close"}), e("span", {key: "text"}, " Details: Hidden")]
          )
        ),
      ),
      e('table', { className: 'table table-striped' },
        e('thead', {},
          e('tr', {},
            e('th', { className: 'right hidden-xs' },
            e('span', { className: 'light glyphicon glyphicon-lock hidden-xs' }, '')),
            e('th', {}, 'URL'),
            e('th', {className: "hidden-xs"}, 'Age'),
            e('th', { className: 'right hidden-xs' }, 'Size'),
            e('th', { className: 'right hidden-xs' },
            e('span', { className: 'glyphicon glyphicon-transfer' }, '')),
            e('th', { className: 'right hidden-xs' }, 'IPs'),
            e('th', { className: 'right hidden-xs' },
            e('span', { className: 'glyphicon glyphicon-flag' }, '')),
            e('th', {},
            e('span', { className: 'glyphicon glyphicon-home' }, '')))),
        e('tbody', {}, uniqBy(@props.results, if @state.collapse then "page.domain" else undefined).map((x, index) =>
          x.detail = @state.detail
          x.index = index+1
          return e(SearchResult, x))
        ),
      ),
      @props.show_more_button and e('button', {
        className: 'btn btn-primary text-center center-block col col-md-2' + (if @props.offset > @props.total - 100 then ' disabled hidden' else '')
        id: 'more'
        onClick: @props.load_more
      }, 'Load more...'), e('p', { className: 'pull-right' }, e('span', {}, '(' + @props.total + ' results in total, ' + @props.results.length + ' shown)'))
    )

export { RootElement, ErrorElement, SearchResult, propify, readableFileSize, tryPuny}
